import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/produtos/adicionar-lote',
        pathMatch: 'full'
    },
    // {
    //     path: 'dashboard',
    //     loadChildren: './dashboard/dashboard.module#DashboardModule',
    //     canActivateChild: [AuthGuard],
    //     canLoad: [AuthGuard]
    // },
    {
        path: 'dashboard',
        redirectTo: '/produtos/adicionar-lote',
        canActivateChild: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: 'perfil',
        loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule),
        canActivateChild: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: 'produtos',
        loadChildren: () => import('./produtos/produtos.module').then(m => m.ProdutosModule),
        canActivateChild: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: 'solicitacoes',
        loadChildren: () => import('./solicitacoes/solicitacoes.module').then(m => m.SolicitacoesModule),
        canActivateChild: [AuthGuard],
        canLoad: [AuthGuard]
    },

];

export const PagesRoutingModule = RouterModule.forChild(routes);