import { Injectable, Injector } from '@angular/core';
import { ToastService,
          AlertService,
          LoadingService,
          UtilsService } from 'app/shared/services/';

@Injectable()
export class BaseService {
  protected _toast: ToastService;
  protected _loading: LoadingService;
  protected _alert: AlertService;

  protected _utils: UtilsService;

  constructor(injector: Injector) {
    console.log('Service Base Loaded');
    this._toast = injector.get(ToastService);
    this._alert = injector.get(AlertService);
    this._loading = injector.get(LoadingService);
    this._utils = injector.get(UtilsService);
  }
}
