import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
    MinusCircle, Plus, Star, Calendar, DownloadCloud, CheckCircle, Upload, List, CreditCard, Info, PlusCircle, Edit, XCircle, MessageCircle,
    Send, X, Smartphone, UserCheck, Package, User, MapPin, FileText, RefreshCcw, FilePlus, ChevronsDown, Folder, Settings, Camera, ShoppingBag, ShoppingCart, Users, Truck,
    Tag, DollarSign, Box, ArrowRightCircle, Activity, Archive, Clipboard, LogOut, Home, Clock, Share2, Save, MoreHorizontal, Facebook, Twitter, Youtube, Trash2, RotateCw,
    ArrowRight, Check, Heart, ExternalLink, Printer, Maximize, Image, Eye, Lock,
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
    MinusCircle,
    Plus,
    Star,
    Calendar,
    DownloadCloud,
    CheckCircle,
    Upload,
    List,
    CreditCard,
    Info,
    PlusCircle,
    Edit,
    XCircle,
    MessageCircle,
    Send,
    X,
    Smartphone,
    UserCheck,
    Package,
    User,
    MapPin,
    FileText,
    RefreshCcw,
    FilePlus,
    ChevronsDown,
    Folder,
    Settings,
    Camera,
    ShoppingBag,
    ShoppingCart,
    Users,
    Truck,
    Tag,
    DollarSign,
    Box,
    ArrowRightCircle,
    Activity,
    Archive,
    Clipboard,
    LogOut,
    Home,
    Clock,
    Share2,
    Save,
    MoreHorizontal,
    Facebook,
    Twitter,
    Youtube,
    Trash2,
    RotateCw,
    ArrowRight,
    Check,
    Heart,
    ExternalLink,
    Printer,
    Maximize,
    Image,
    Eye,
    Lock
};

@NgModule({
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ]
})
export class IconsModule { }

// NOTES:
// 1. We add FeatherModule to the 'exports', since the <i-feather> component will be used in templates of parent module
// 2. Don't forget to pick some icons using FeatherModule.pick({ ... })