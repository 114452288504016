/* tslint:disable */

declare var Object: any;
export interface UsuarioInterface {
  "id"?: string;
  "codigoAntigo"?: string;
  "tipo"?: string;
  "nome"?: string;
  "cpf"?: string;
  "cnpj"?: string;
  "rg"?: string;
  "dataNascimento"?: string;
  "sexo"?: string;
  "telefoneResidencial"?: string;
  "telefoneComercial"?: string;
  "celular"?: string;
  "whatsapp"?: string;
  "cep"?: string;
  "logradouro"?: string;
  "numero"?: string;
  "complemento"?: string;
  "bairro"?: string;
  "cidade"?: string;
  "estado"?: string;
  "pais"?: string;
  "banco"?: string;
  "agencia"?: string;
  "conta"?: number;
  "digito"?: number;
  "tipoconta"?: string;
  "titular"?: string;
  "doc_titular"?: string;
  "informacoes"?: string;
  "ativo"?: boolean;
  "created"?: Date;
  "estadoCivil"?: string;
  "apelido"?: string;
  "hasToResetPassword"?: boolean;
  "ativoFeito"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password"?: string;
  accessTokens?: any[];
  solicitacoes?: any[];
  produtos?: any[];
  produtosVendidos?: any[];
  reservas?: any[];
  carteira?: any;
  dadosBancarios?: any[];
}

export class Usuario implements UsuarioInterface {
  "id": string;
  "codigoAntigo": string;
  "tipo": string;
  "nome": string;
  "cpf": string;
  "cnpj": string;
  "rg": string;
  "dataNascimento": string;
  "sexo": string;
  "telefoneResidencial": string;
  "telefoneComercial": string;
  "celular": string;
  "whatsapp": string;
  "cep": string;
  "logradouro": string;
  "numero": string;
  "complemento": string;
  "bairro": string;
  "cidade": string;
  "estado": string;
  "pais": string;
  "banco": string;
  "agencia": string;
  "conta": number;
  "digito": number;
  "tipoconta": string;
  "titular": string;
  "doc_titular": string;
  "informacoes": string;
  "ativo": boolean;
  "created": Date;
  "estadoCivil": string;
  "apelido": string;
  "hasToResetPassword": boolean;
  "ativoFeito": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password": string;
  accessTokens: any[];
  solicitacoes: any[];
  produtos: any[];
  produtosVendidos: any[];
  reservas: any[];
  carteira: any;
  dadosBancarios: any[];
  constructor(data?: UsuarioInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Usuario`.
   */
  public static getModelName() {
    return "Usuario";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Usuario for dynamic purposes.
  **/
  public static factory(data: UsuarioInterface): Usuario{
    return new Usuario(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Usuario',
      plural: 'Usuarios',
      path: 'Usuarios',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "codigoAntigo": {
          name: 'codigoAntigo',
          type: 'string'
        },
        "tipo": {
          name: 'tipo',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "cpf": {
          name: 'cpf',
          type: 'string'
        },
        "cnpj": {
          name: 'cnpj',
          type: 'string'
        },
        "rg": {
          name: 'rg',
          type: 'string'
        },
        "dataNascimento": {
          name: 'dataNascimento',
          type: 'string'
        },
        "sexo": {
          name: 'sexo',
          type: 'string'
        },
        "telefoneResidencial": {
          name: 'telefoneResidencial',
          type: 'string'
        },
        "telefoneComercial": {
          name: 'telefoneComercial',
          type: 'string'
        },
        "celular": {
          name: 'celular',
          type: 'string'
        },
        "whatsapp": {
          name: 'whatsapp',
          type: 'string'
        },
        "cep": {
          name: 'cep',
          type: 'string'
        },
        "logradouro": {
          name: 'logradouro',
          type: 'string'
        },
        "numero": {
          name: 'numero',
          type: 'string'
        },
        "complemento": {
          name: 'complemento',
          type: 'string'
        },
        "bairro": {
          name: 'bairro',
          type: 'string'
        },
        "cidade": {
          name: 'cidade',
          type: 'string'
        },
        "estado": {
          name: 'estado',
          type: 'string'
        },
        "pais": {
          name: 'pais',
          type: 'string'
        },
        "banco": {
          name: 'banco',
          type: 'string'
        },
        "agencia": {
          name: 'agencia',
          type: 'string'
        },
        "conta": {
          name: 'conta',
          type: 'number'
        },
        "digito": {
          name: 'digito',
          type: 'number'
        },
        "tipoconta": {
          name: 'tipoconta',
          type: 'string'
        },
        "titular": {
          name: 'titular',
          type: 'string'
        },
        "doc_titular": {
          name: 'doc_titular',
          type: 'string'
        },
        "informacoes": {
          name: 'informacoes',
          type: 'string'
        },
        "ativo": {
          name: 'ativo',
          type: 'boolean',
          default: true
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "estadoCivil": {
          name: 'estadoCivil',
          type: 'string'
        },
        "apelido": {
          name: 'apelido',
          type: 'string'
        },
        "hasToResetPassword": {
          name: 'hasToResetPassword',
          type: 'boolean',
          default: false
        },
        "ativoFeito": {
          name: 'ativoFeito',
          type: 'boolean',
          default: false
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        solicitacoes: {
          name: 'solicitacoes',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'usuarioId'
        },
        produtos: {
          name: 'produtos',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'usuarioId'
        },
        produtosVendidos: {
          name: 'produtosVendidos',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'antigoProprietarioId'
        },
        reservas: {
          name: 'reservas',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'Reserva',
          keyThrough: 'produtoId',
          keyFrom: 'id',
          keyTo: 'usuarioId'
        },
        carteira: {
          name: 'carteira',
          type: 'any',
          model: '',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'usuarioId'
        },
        dadosBancarios: {
          name: 'dadosBancarios',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'usuarioId'
        },
      }
    }
  }
}
