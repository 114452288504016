/* tslint:disable */
import {
  Produto,
  Usuario
} from '../index';

declare var Object: any;
export interface HistoricoProdutoInterface {
  "id"?: string;
  "versao"?: any;
  "produtoId"?: string;
  "usuarioId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  produto?: Produto;
  usuario?: Usuario;
}

export class HistoricoProduto implements HistoricoProdutoInterface {
  "id": string;
  "versao": any;
  "produtoId": string;
  "usuarioId": string;
  "createdAt": Date;
  "updatedAt": Date;
  produto: Produto;
  usuario: Usuario;
  constructor(data?: HistoricoProdutoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `HistoricoProduto`.
   */
  public static getModelName() {
    return "HistoricoProduto";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of HistoricoProduto for dynamic purposes.
  **/
  public static factory(data: HistoricoProdutoInterface): HistoricoProduto{
    return new HistoricoProduto(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'HistoricoProduto',
      plural: 'HistoricoProdutos',
      path: 'HistoricoProdutos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "versao": {
          name: 'versao',
          type: 'any'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "usuarioId": {
          name: 'usuarioId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'usuarioId',
          keyTo: 'id'
        },
      }
    }
  }
}
