import { Component, OnInit, Injector, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'app/shared/services/base';
import { environment } from '@env/environment';
declare var $: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent extends BaseService implements OnInit, AfterViewInit {
  public vendedorUrl: string = environment.VENDEDOR_URL;

  constructor(private _base: Injector, private _router: Router) {
    super(_base);
  }

  public ngOnInit() {}

  public ngAfterViewInit() {
    $('.popup-with-zoom-anim').magnificPopup({
      type: 'inline',
      preloader: false,
    });

    $('#navigation-mobile').mmenu();

    let API = $('#navigation-mobile').data('mmenu');

    $('.mmenu-trigger').click(function () {
      API.open();
    });

    $('.menu-closer').click(function () {
      API.close();
    });
  }

  public buscar(event): void {
    // this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/buscar', event.target.text, '']);
  }

  public desapegar(): void {
    this._router.navigate(['/desapegar']);
  }
}
