/* tslint:disable */
import {
  Usuario,
  Hub,
  Transportador,
  Transacao,
  Produto
} from '../index';

declare var Object: any;
export interface TransporteInterface {
  "id"?: string;
  "data": Date;
  "periodo"?: string;
  "responsavel"?: string;
  "plano"?: string;
  "contatoResponsavel"?: string;
  "status": string;
  "tipo"?: string;
  "obs"?: string;
  "enderecoBancoAntigo"?: string;
  "logradouro"?: string;
  "numero"?: string;
  "complemento"?: string;
  "bairro"?: string;
  "cidade"?: string;
  "estado"?: string;
  "cep"?: string;
  "regiaoOrigem"?: string;
  "regiaoDestino"?: string;
  "usuarioId"?: string;
  "hubId"?: string;
  "transportadorId"?: string;
  "transacaoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  usuario?: Usuario;
  hub?: Hub;
  transportador?: Transportador;
  pagamento?: Transacao;
  produtos?: Produto[];
}

export class Transporte implements TransporteInterface {
  "id": string;
  "data": Date;
  "periodo": string;
  "responsavel": string;
  "plano": string;
  "contatoResponsavel": string;
  "status": string;
  "tipo": string;
  "obs": string;
  "enderecoBancoAntigo": string;
  "logradouro": string;
  "numero": string;
  "complemento": string;
  "bairro": string;
  "cidade": string;
  "estado": string;
  "cep": string;
  "regiaoOrigem": string;
  "regiaoDestino": string;
  "usuarioId": string;
  "hubId": string;
  "transportadorId": string;
  "transacaoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  usuario: Usuario;
  hub: Hub;
  transportador: Transportador;
  pagamento: Transacao;
  produtos: Produto[];
  constructor(data?: TransporteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transporte`.
   */
  public static getModelName() {
    return "Transporte";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transporte for dynamic purposes.
  **/
  public static factory(data: TransporteInterface): Transporte{
    return new Transporte(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transporte',
      plural: 'Transportes',
      path: 'Transportes',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'Date'
        },
        "periodo": {
          name: 'periodo',
          type: 'string'
        },
        "responsavel": {
          name: 'responsavel',
          type: 'string'
        },
        "plano": {
          name: 'plano',
          type: 'string'
        },
        "contatoResponsavel": {
          name: 'contatoResponsavel',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "tipo": {
          name: 'tipo',
          type: 'string'
        },
        "obs": {
          name: 'obs',
          type: 'string'
        },
        "enderecoBancoAntigo": {
          name: 'enderecoBancoAntigo',
          type: 'string'
        },
        "logradouro": {
          name: 'logradouro',
          type: 'string'
        },
        "numero": {
          name: 'numero',
          type: 'string'
        },
        "complemento": {
          name: 'complemento',
          type: 'string'
        },
        "bairro": {
          name: 'bairro',
          type: 'string'
        },
        "cidade": {
          name: 'cidade',
          type: 'string'
        },
        "estado": {
          name: 'estado',
          type: 'string'
        },
        "cep": {
          name: 'cep',
          type: 'string'
        },
        "regiaoOrigem": {
          name: 'regiaoOrigem',
          type: 'string'
        },
        "regiaoDestino": {
          name: 'regiaoDestino',
          type: 'string'
        },
        "usuarioId": {
          name: 'usuarioId',
          type: 'string'
        },
        "hubId": {
          name: 'hubId',
          type: 'string'
        },
        "transportadorId": {
          name: 'transportadorId',
          type: 'string'
        },
        "transacaoId": {
          name: 'transacaoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'usuarioId',
          keyTo: 'id'
        },
        hub: {
          name: 'hub',
          type: 'Hub',
          model: 'Hub',
          relationType: 'belongsTo',
                  keyFrom: 'hubId',
          keyTo: 'id'
        },
        transportador: {
          name: 'transportador',
          type: 'Transportador',
          model: 'Transportador',
          relationType: 'belongsTo',
                  keyFrom: 'transportadorId',
          keyTo: 'id'
        },
        pagamento: {
          name: 'pagamento',
          type: 'Transacao',
          model: 'Transacao',
          relationType: 'belongsTo',
                  keyFrom: 'transacaoId',
          keyTo: 'id'
        },
        produtos: {
          name: 'produtos',
          type: 'Produto[]',
          model: 'Produto',
          relationType: 'hasMany',
          modelThrough: 'ProdutoTransporte',
          keyThrough: 'produtoId',
          keyFrom: 'id',
          keyTo: 'transporteId'
        },
      }
    }
  }
}
