/* tslint:disable */
import { Injectable } from '@angular/core';
import { SolicitacaoServico } from '../../models/SolicitacaoServico';
import { Transacao } from '../../models/Transacao';
import { Transportador } from '../../models/Transportador';
import { Reserva } from '../../models/Reserva';
import { Usuario } from '../../models/Usuario';
import { Vendedor } from '../../models/Vendedor';
import { Hub } from '../../models/Hub';
import { Transporte } from '../../models/Transporte';
import { Subcategoria } from '../../models/Subcategoria';
import { Produto } from '../../models/Produto';
import { Venda } from '../../models/Venda';
import { Carteira } from '../../models/Carteira';
import { ProdutoFotos } from '../../models/ProdutoFotos';
import { Container } from '../../models/Container';
import { Categoria } from '../../models/Categoria';
import { Plano } from '../../models/Plano';
import { Fotografo } from '../../models/Fotografo';
import { FAQ } from '../../models/FAQ';
import { DadosBancario } from '../../models/DadosBancario';
import { PostagemSocial } from '../../models/PostagemSocial';
import { TipoPostagem } from '../../models/TipoPostagem';
import { Marca } from '../../models/Marca';
import { Secao } from '../../models/Secao';
import { SecoesEProdutos } from '../../models/SecoesEProdutos';
import { ProdutoTransporte } from '../../models/ProdutoTransporte';
import { Integracao } from '../../models/Integracao';
import { Box } from '../../models/Box';
import { HistoricoProduto } from '../../models/HistoricoProduto';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    SolicitacaoServico: SolicitacaoServico,
    Transacao: Transacao,
    Transportador: Transportador,
    Reserva: Reserva,
    Usuario: Usuario,
    Vendedor: Vendedor,
    Hub: Hub,
    Transporte: Transporte,
    Subcategoria: Subcategoria,
    Produto: Produto,
    Venda: Venda,
    Carteira: Carteira,
    ProdutoFotos: ProdutoFotos,
    Container: Container,
    Categoria: Categoria,
    Plano: Plano,
    Fotografo: Fotografo,
    FAQ: FAQ,
    DadosBancario: DadosBancario,
    PostagemSocial: PostagemSocial,
    TipoPostagem: TipoPostagem,
    Marca: Marca,
    Secao: Secao,
    SecoesEProdutos: SecoesEProdutos,
    ProdutoTransporte: ProdutoTransporte,
    Integracao: Integracao,
    Box: Box,
    HistoricoProduto: HistoricoProduto,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
