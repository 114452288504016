/* tslint:disable */
import {
  Produto,
  Box
} from '../index';

declare var Object: any;
export interface HubInterface {
  "id"?: string;
  "nome": string;
  "informacoes"?: string;
  "contato"?: string;
  "email"?: string;
  "telefone"?: string;
  "celular"?: string;
  "logradouro"?: string;
  "numero"?: string;
  "complemento"?: string;
  "bairro"?: string;
  "cidade"?: string;
  "estado"?: string;
  "cep"?: string;
  "pais"?: string;
  "site"?: string;
  "horarios"?: any;
  "linkedin"?: string;
  "facebook"?: string;
  "twitter"?: string;
  "instagram"?: string;
  "youtube"?: string;
  "s3folder"?: string;
  "logourl"?: string;
  "bannerUrl"?: string;
  "enderecourl"?: string;
  "ativo"?: boolean;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password"?: string;
  accessTokens?: any[];
  produtos?: Produto[];
  boxes?: Box[];
}

export class Hub implements HubInterface {
  "id": string;
  "nome": string;
  "informacoes": string;
  "contato": string;
  "email": string;
  "telefone": string;
  "celular": string;
  "logradouro": string;
  "numero": string;
  "complemento": string;
  "bairro": string;
  "cidade": string;
  "estado": string;
  "cep": string;
  "pais": string;
  "site": string;
  "horarios": any;
  "linkedin": string;
  "facebook": string;
  "twitter": string;
  "instagram": string;
  "youtube": string;
  "s3folder": string;
  "logourl": string;
  "bannerUrl": string;
  "enderecourl": string;
  "ativo": boolean;
  "realm": string;
  "username": string;
  "emailVerified": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password": string;
  accessTokens: any[];
  produtos: Produto[];
  boxes: Box[];
  constructor(data?: HubInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Hub`.
   */
  public static getModelName() {
    return "Hub";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Hub for dynamic purposes.
  **/
  public static factory(data: HubInterface): Hub{
    return new Hub(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Hub',
      plural: 'Hubs',
      path: 'Hubs',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "informacoes": {
          name: 'informacoes',
          type: 'string'
        },
        "contato": {
          name: 'contato',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "telefone": {
          name: 'telefone',
          type: 'string'
        },
        "celular": {
          name: 'celular',
          type: 'string'
        },
        "logradouro": {
          name: 'logradouro',
          type: 'string'
        },
        "numero": {
          name: 'numero',
          type: 'string'
        },
        "complemento": {
          name: 'complemento',
          type: 'string'
        },
        "bairro": {
          name: 'bairro',
          type: 'string'
        },
        "cidade": {
          name: 'cidade',
          type: 'string'
        },
        "estado": {
          name: 'estado',
          type: 'string'
        },
        "cep": {
          name: 'cep',
          type: 'string'
        },
        "pais": {
          name: 'pais',
          type: 'string'
        },
        "site": {
          name: 'site',
          type: 'string'
        },
        "horarios": {
          name: 'horarios',
          type: 'any'
        },
        "linkedin": {
          name: 'linkedin',
          type: 'string'
        },
        "facebook": {
          name: 'facebook',
          type: 'string'
        },
        "twitter": {
          name: 'twitter',
          type: 'string'
        },
        "instagram": {
          name: 'instagram',
          type: 'string'
        },
        "youtube": {
          name: 'youtube',
          type: 'string'
        },
        "s3folder": {
          name: 's3folder',
          type: 'string'
        },
        "logourl": {
          name: 'logourl',
          type: 'string'
        },
        "bannerUrl": {
          name: 'bannerUrl',
          type: 'string'
        },
        "enderecourl": {
          name: 'enderecourl',
          type: 'string'
        },
        "ativo": {
          name: 'ativo',
          type: 'boolean'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        produtos: {
          name: 'produtos',
          type: 'Produto[]',
          model: 'Produto',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'hubId'
        },
        boxes: {
          name: 'boxes',
          type: 'Box[]',
          model: 'Box',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'hubId'
        },
      }
    }
  }
}
