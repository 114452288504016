import { TextMaskModule } from 'angular2-text-mask';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DataModule } from 'app/data';
import { AlertModule } from 'app/shared/services/alert';
import { LoadingModule } from 'app/shared/services/loading';
import { ToastModule } from 'app/shared/services/toast';
import { TranslateModule } from 'app/shared/services/translate';
import { NotificationBarModule } from 'app/shared/services/notification/notification-bar.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LayoutModule } from 'app/layout/layout.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    TextMaskModule,

    DataModule.forRoot(),
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    LoadingModule.forRoot(),
    ToastModule.forRoot(),
    TranslateModule.forRoot(),
    NotificationBarModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  exports: [LoadingModule, NotificationBarModule, LayoutModule, TextMaskModule],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import modules in the AppModule only.`);
  }
}
