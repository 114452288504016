/* tslint:disable */
import {
  Produto,
  Transporte
} from '../index';

declare var Object: any;
export interface ProdutoTransporteInterface {
  "id"?: string;
  "produtoId"?: string;
  "transporteId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  produto?: Produto;
  transporte?: Transporte;
}

export class ProdutoTransporte implements ProdutoTransporteInterface {
  "id": string;
  "produtoId": string;
  "transporteId": string;
  "createdAt": Date;
  "updatedAt": Date;
  produto: Produto;
  transporte: Transporte;
  constructor(data?: ProdutoTransporteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProdutoTransporte`.
   */
  public static getModelName() {
    return "ProdutoTransporte";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProdutoTransporte for dynamic purposes.
  **/
  public static factory(data: ProdutoTransporteInterface): ProdutoTransporte{
    return new ProdutoTransporte(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProdutoTransporte',
      plural: 'ProdutoTransportes',
      path: 'ProdutoTransportes',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "transporteId": {
          name: 'transporteId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
        transporte: {
          name: 'transporte',
          type: 'Transporte',
          model: 'Transporte',
          relationType: 'belongsTo',
                  keyFrom: 'transporteId',
          keyTo: 'id'
        },
      }
    }
  }
}
