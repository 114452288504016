/* tslint:disable */
import {
  Transporte,
  Venda,
  Carteira,
  DadosBancario
} from '../index';

declare var Object: any;
export interface TransacaoInterface {
  "id"?: string;
  "tipo"?: string;
  "valor"?: number;
  "status"?: string;
  "observacao"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  "vendaId"?: string;
  "carteiraId"?: string;
  "dadosbancariosId"?: string;
  transporte?: Transporte;
  venda?: Venda;
  carteira?: Carteira;
  dadosbancarios?: DadosBancario;
}

export class Transacao implements TransacaoInterface {
  "id": string;
  "tipo": string;
  "valor": number;
  "status": string;
  "observacao": string;
  "createdAt": Date;
  "updatedAt": Date;
  "vendaId": string;
  "carteiraId": string;
  "dadosbancariosId": string;
  transporte: Transporte;
  venda: Venda;
  carteira: Carteira;
  dadosbancarios: DadosBancario;
  constructor(data?: TransacaoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transacao`.
   */
  public static getModelName() {
    return "Transacao";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transacao for dynamic purposes.
  **/
  public static factory(data: TransacaoInterface): Transacao{
    return new Transacao(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transacao',
      plural: 'Transacoes',
      path: 'Transacoes',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "tipo": {
          name: 'tipo',
          type: 'string',
          default: '0'
        },
        "valor": {
          name: 'valor',
          type: 'number',
          default: 0
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "observacao": {
          name: 'observacao',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "vendaId": {
          name: 'vendaId',
          type: 'string'
        },
        "carteiraId": {
          name: 'carteiraId',
          type: 'string'
        },
        "dadosbancariosId": {
          name: 'dadosbancariosId',
          type: 'string'
        },
      },
      relations: {
        transporte: {
          name: 'transporte',
          type: 'Transporte',
          model: 'Transporte',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'transacaoId'
        },
        venda: {
          name: 'venda',
          type: 'Venda',
          model: 'Venda',
          relationType: 'belongsTo',
                  keyFrom: 'vendaId',
          keyTo: 'id'
        },
        carteira: {
          name: 'carteira',
          type: 'Carteira',
          model: 'Carteira',
          relationType: 'belongsTo',
                  keyFrom: 'carteiraId',
          keyTo: 'id'
        },
        dadosbancarios: {
          name: 'dadosbancarios',
          type: 'DadosBancario',
          model: 'DadosBancario',
          relationType: 'belongsTo',
                  keyFrom: 'dadosbancariosId',
          keyTo: 'id'
        },
      }
    }
  }
}
