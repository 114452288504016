import { Injectable } from '@angular/core';
import { Fotografo } from 'app/api';
import { LoopBackAuth, FotografoApi } from 'app/api/services/';

@Injectable()
export class AuthService {

    constructor(
        public authApi: LoopBackAuth,
        public _fotografoApi: FotografoApi,
    ) { }

    public login(fotografo: Fotografo): any {
        return this._fotografoApi.login({ email: fotografo.email, password: fotografo.password });
    }

    public get fotografo(): Fotografo {
        return this.authApi.getCurrentUserData();
    }

    public set fotografo(value) {
        this.authApi.setUser(value);
    }

    public get fotografoToken(): string {
        return this.authApi.getAccessTokenId();
    }

    public logged(): boolean {
        return this._fotografoApi.isAuthenticated();
    }

    public async register(fotografo: Fotografo): Promise<any> {
        return this._fotografoApi.create(fotografo).toPromise();
    }

    public logout() {
        this._fotografoApi.logout();
    }

    public async reset(email: string): Promise<any> {
        return this._fotografoApi.resetPassword({ email }).toPromise();
    }

    public async setPassword(newPassword: string): Promise<any> {
        return this._fotografoApi.setPassword(newPassword).toPromise();
    }

    public async changePassword(oldPassword: string, newPassword: string): Promise<any> {
        return this._fotografoApi.changePassword(oldPassword, newPassword).toPromise()
    }

}
