<header id="header-container" class="fixed fullwidth dashboard">
  <!-- Header -->
  <div id="header" class="not-sticky">
    <div class="container">
      <div class="left-side">
        <app-mobile-nav></app-mobile-nav>
        <div id="logo">
          <a routerLink="/dashboard">
            <img class="logo-img" src="assets/images/Tag2u-logo.png" alt="">
          </a>
          <a href="index.html" class="dashboard-logo">
            <img src="assets/images/Tag2u-logo.png" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</header>