import { NgModule, ModuleWithProviders } from '@angular/core';

import { AlertService } from './alert.service';

@NgModule({})

export class AlertModule {

    constructor() {

    }

    public static forRoot(): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [
                AlertService
            ]
        };
    }

}
