/* tslint:disable */

declare var Object: any;
export interface SubcategoriaInterface {
  "id"?: string;
  "nome": string;
  "categoriaPaiId"?: string;
  "escolhida": boolean;
  "createdAt": Date;
  "updatedAt": Date;
}

export class Subcategoria implements SubcategoriaInterface {
  "id": string;
  "nome": string;
  "categoriaPaiId": string;
  "escolhida": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SubcategoriaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Subcategoria`.
   */
  public static getModelName() {
    return "Subcategoria";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Subcategoria for dynamic purposes.
  **/
  public static factory(data: SubcategoriaInterface): Subcategoria{
    return new Subcategoria(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Subcategoria',
      plural: 'Subcategorias',
      path: 'Subcategorias',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "categoriaPaiId": {
          name: 'categoriaPaiId',
          type: 'string',
          default: 'null'
        },
        "escolhida": {
          name: 'escolhida',
          type: 'boolean',
          default: true
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
