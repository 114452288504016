import { AuthService } from '../app/auth/auth.service';
import { environment } from '../environments/environment';
import { Component, OnInit } from '@angular/core';
import { LoopBackConfig } from '../app/api';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title: string = 'app';
  public isMobile: boolean;

  constructor(public authService: AuthService, private router: Router) {
    if (!this.authService.logged) {
      this.router.navigate(['/login']);
    }

    LoopBackConfig.setBaseURL(environment.API_URL);
    LoopBackConfig.setApiVersion(environment.API_VERSION);
    LoopBackConfig.setDebugMode(environment.API_DEBUG);
  }

  public ngOnInit(): void {
    this.isMobile = 769 > window.innerWidth;
    moment.locale('pt-br');
  }
}
