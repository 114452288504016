import { BaseService } from 'app/shared/services/base';
import { FotografoService } from 'app/data/fotografo';
import { Component, OnInit, Injector } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { Fotografo } from 'app/api//models';
import { AuthService } from '../auth.service';
import { CUSTOM_MASKS } from 'app/shared/services';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['register.component.scss']
})
export class RegisterComponent extends BaseService implements OnInit {
  public usuario: Fotografo = new Fotografo();
  public confirm: string;
  public loading = false;
  public returnUrl: string;
  public celularMask = CUSTOM_MASKS.masks.celular;
  public cellPhoneMask = ['(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /[0-9]/, /\d/, /\d/, /\d/];

  constructor(
    private _base: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private _fotografoService: FotografoService,
    private _auth: AuthService
  ) {
    super(_base);
  }

  public ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
  }

  public changeCellphoneMask(event: any): void{
    if(event.target.value.length < 12){
      this.cellPhoneMask = ['(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /[0-9]/, /\d/, /\d/, /\d/];
    } else {
      this.cellPhoneMask = ['(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/,/\d/, '-', /[0-9]/, /\d/, /\d/, /\d/];
    }
  }

  public async checkData(e: any): Promise<void> {
    e.preventDefault();

    if (this.confirm !== this.usuario.password) {
      this._toast.error("Senhas não coincidem");
      return;
    }

    if (this.confirm.length < 6) {
      this._toast.error("A senha deve ter mais que 6 caracteres");
      return;
    }

    // await this._fotografoService.checkEmail(this.usuario.email)
    //   .then(res => {
    //     if (res) {
    //       this._toast.error("Esse email já está cadastrado");
    //       throw '"Esse email já está cadastrado"';
    //     }
    //   })


    // await this._fotografoService.checkCelular(this.usuario.celular)
    //   .then(res => {
    //     if (res) {
    //       this._toast.error("Esse celular já está cadastrado");
    //       throw '"Esse celular já está cadastrado"';
    //     }
    //   })

    this.saveUser();
  }


  public saveUser(): void {
    this.loading = true;

    this._fotografoService.createFotografo(this.usuario)
      .then(() => {
        this._toast.success("Cadastro realizado com sucesso!");
        this.login();
      })
      .catch(err => this._toast.error(err.message));
  }

  public login(): void {
    this._auth.login(this.usuario).toPromise()
    .then( () => {
      if (this._auth.logged()) {
        this._toast.success('Login realizado com sucesso');
        this.router.navigateByUrl('/dashboard');
      }
    }).catch(error => this._toast.error(error));
  }
}
