/* tslint:disable */
import {
  Produto
} from '../index';

declare var Object: any;
export interface SecaoInterface {
  "id"?: string;
  "nome": string;
  "descricao": string;
  "url": string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos?: Produto[];
}

export class Secao implements SecaoInterface {
  "id": string;
  "nome": string;
  "descricao": string;
  "url": string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos: Produto[];
  constructor(data?: SecaoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Secao`.
   */
  public static getModelName() {
    return "Secao";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Secao for dynamic purposes.
  **/
  public static factory(data: SecaoInterface): Secao{
    return new Secao(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Secao',
      plural: 'Secoes',
      path: 'Secoes',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "descricao": {
          name: 'descricao',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produtos: {
          name: 'produtos',
          type: 'Produto[]',
          model: 'Produto',
          relationType: 'hasMany',
          modelThrough: 'SecoesEProdutos',
          keyThrough: 'produtoId',
          keyFrom: 'id',
          keyTo: 'secaoId'
        },
      }
    }
  }
}
