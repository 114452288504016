<nav role="navigation">
  <div id="menuToggle">
    <input type="checkbox" [(ngModel)]="menuStatus" #menuTrigger name="menuTrigger" />

    <!--
      Some spans to act as a hamburger.
      They are acting like a real hamburger,
      not that McDonalds stuff.
      -->
    <span></span>
    <span></span>
    <span></span>

    <ul id="menu">
      <li routerLinkActive="active" (click)="toggleMenu()">
        <a routerLink="/dashboard">
          <i-feather class="icon" name="home"></i-feather> Visão geral
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu()">
        <a routerLink="/produtos/adicionar-lote">
          <i-feather class="icon" name="plus-circle"></i-feather> Adicionar Lote
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu()">
        <a routerLink="/produtos">
          <i-feather class="icon" name="archive"></i-feather>Todos os Produtos
        </a>
      </li>
      <li routerLinkActive="active" (click)="toggleMenu()">
        <a routerLink="/solicitacoes">
          <i-feather class="icon" name="camera"></i-feather> Visita técnica
        </a>
      </li>
      <li routerLinkActive="active" (click)="toggleMenu()">
        <a routerLink="/perfil">
          <i-feather class="icon" name="user"></i-feather> Meu Perfil
        </a>
      </li>
      <li (click)="toggleMenu()">
        <a [routerLink]="['login']" (click)="logout()">
          <i-feather class="icon" name="log-out"></i-feather> Sair
        </a>
      </li>
    </ul>
  </div>
</nav>