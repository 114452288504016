import { BaseService } from 'app/shared/services/base';
import { Injectable, Injector } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Route } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard extends BaseService implements CanActivate {

    constructor(
        private _base: Injector,
        private authService: AuthService,
        private router: Router) {
        super(_base);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.authService._fotografoApi.getCurrent()
                .subscribe(curr => {
                    resolve(true);
                }, () => {
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    resolve(false);
                });
        });
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): Promise<boolean> {
        return this.canAccess();
    }

    private canAccess(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.authService._fotografoApi.getCurrent()
                .subscribe(
                    curr => resolve(true), 
                () => {
                    this.router.navigate(['/login']);
                    resolve(false);
                });
        });
    }
}
