import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from 'app/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from 'app/core/core.module';
import { RouterModule } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { CommonModule } from '@angular/common';
import { DataModule } from './data';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'ngx-moment';
import { NgxCurrencyModule } from 'ngx-currency';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  imports: [
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule,
    PagesModule,
    DataModule,
    LayoutModule,
    SharedModule,
    NgxPaginationModule,
    MomentModule,
    NgxCurrencyModule,
    LightboxModule,
  ],
  declarations: [
    AppComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
