/* tslint:disable */
import {
  Vendedor
} from '../index';

declare var Object: any;
export interface PlanoInterface {
  "id"?: string;
  "nome": string;
  "createdAt": Date;
  "updatedAt": Date;
  vendedor?: Vendedor[];
}

export class Plano implements PlanoInterface {
  "id": string;
  "nome": string;
  "createdAt": Date;
  "updatedAt": Date;
  vendedor: Vendedor[];
  constructor(data?: PlanoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Plano`.
   */
  public static getModelName() {
    return "Plano";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Plano for dynamic purposes.
  **/
  public static factory(data: PlanoInterface): Plano{
    return new Plano(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Plano',
      plural: 'Planos',
      path: 'Planos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        vendedor: {
          name: 'vendedor',
          type: 'Vendedor[]',
          model: 'Vendedor',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planoId'
        },
      }
    }
  }
}
