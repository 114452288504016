import { Fotografo, LoopBackFilter } from 'app/api/models/';
import { AuthService } from 'app/auth/auth.service';
import { Injectable } from '@angular/core';
import { FotografoApi } from 'app/api';

@Injectable({
    providedIn: 'root'
})
export class FotografoService {
    constructor(
        private _authService: AuthService,
        private _fotografoApi: FotografoApi
    ) { }

    public get fotografo(): Fotografo {
        return this._authService.fotografo;
    }

    public set fotografo(value) {
        this._authService.fotografo = value;
    }

    public get token() {
        return this._authService.fotografoToken;
    }

    public async createFotografo(fotografo: Fotografo): Promise<any> {
        return this._fotografoApi.create(fotografo).toPromise();
    }

    public getFotografo(filter?: LoopBackFilter): Promise<any> {
        return this._authService._fotografoApi.findById(this.fotografo.id, filter).toPromise();
    }

    public async upsertFotografo(fotografo: Fotografo): Promise<any> {
        return this._authService._fotografoApi.patchAttributes(this.fotografo.id, fotografo).toPromise();
    }

    public validaCPF(strCPF: string): boolean {
        if (strCPF === '' || strCPF === undefined) {
            return false;
        }

        if (strCPF === '00000000000') {
            return false;
        }

        strCPF = strCPF.replace(/\./g, '').replace(/-/, '');
        let soma;
        let resto;
        soma = 0;

        for (let i = 1; i <= 9; i++) {
            soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        
        
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(strCPF.substring(9, 10))) {
            return false;
        }

        soma = 0;

        for (let i = 1; i <= 10; i++) {
            soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(strCPF.substring(10, 11))) {
            return false;
        }

        return true;
    }

    public async getFotografoById(id: string, filter?: LoopBackFilter): Promise<any> {
        return this._fotografoApi.findById(id, filter).toPromise();
    }

    // public async checkCPF(cpf: string, fotografoId: string = '0'): Promise<any> {
        // return this._fotografoApi.checkCPF(cpf, fotografoId);
    // }

    // public async checkCelular(celular: string, fotografoId: string = '0'): Promise<any> {
    //     return  this._fotografoApi.checkCelular(celular, fotografoId) ;
    // }

    // public async checkEmail(email: string, fotografoId: string = '0'): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._fotografoApi.checkEmail(email, fotografoId)
    //             .subscribe(
    //                 result => resolve(result.email),
    //                 error => reject(error)
    //             );
    //     });
    // }
}
