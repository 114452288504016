<div class="container reset-container">
  <div class="login-panel panel panel-default" *ngIf="!states.requestSent">
    <!-- <div class="panel-heading">
      <h3 class="panel-title">Resetar Senha</h3>
    </div> -->
    <div class="panel-body" *ngIf="!states.alreadySendedEmail">
      <img src="assets/images/forgot.svg" class="reset-password-image" alt="">
      <h3 class="reset-title center">Por favor, informe seu email.</h3>
      <h3 class="reset-title center">Nós enviaremos um link para atualizar sua senha.</h3>
      <form name="requestForm" (submit)="sendRequest($event, email.value)" novalidate #requestForm="ngForm">
        <fieldset>
          <div class="form-group">
            <input class="form-control" form="requestForm" ngModel type="email" id="email" #email name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autofocus>
          </div>

          <button type="submit" class="button big center" [disabled]="!requestForm.form.valid">Enviar</button>
          <button type="button" class="flat-button center" routerLink="/login">Voltar para tela de login</button>
        </fieldset>
      </form>
    </div>

    <div class="panel-body" *ngIf="states.alreadySendedEmail">
      <img src="assets/images/password.svg" class="reset-password-image" alt="">
      <h3 class="reset-title center">Por favor, informe uma nova senha.</h3>
      <form name="resetForm" (submit)="createNewPassword($event, password.value, confirm.value)" novalidate #resetForm="ngForm">
        <fieldset>
          <div class="form-group">
            <input class="form-control" form="resetForm" ngModel type="password" id="password" #password name="password"
              required autofocus>
          </div>

          <div class="form-group">
            <input class="form-control" form="resetForm" ngModel type="password" id="confirm" #confirm name="confirm"
              required>
          </div>

          <button type="submit" class="button big center" [disabled]="!resetForm.form.valid">Alterar</button>
          <button type="button" class="flat-button center" routerLink="/login">Voltar para tela de login</button>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<div *ngIf="states.requestSent" class="email-sent">
  <img src="assets/images/email_sent.svg" alt="">
  <h3 class="center">Email enviado. Confira a sua caixa de entrada.</h3>
  <button type="button" class="flat-button center" routerLink="/login">Voltar para tela de login</button>
</div>