import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationBarService {
  public _active = false;
  public status: Subject<boolean> = new Subject();

  public message = '';

  constructor() {

  }

  public get active(): boolean {
      return this._active;
  }

  public set active(value: boolean) {
      this._active = value;
      this.status.next(value);
  }

  public show(): void {
      this._active = true;
  }

  public hide(): void {
      this._active = false;
  }

  public notify(msg: string): void {
    this.message = msg;
    this._active = true;
  }

}
