import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages.routes';

@NgModule({
    imports: [
        PagesRoutingModule
    ],
    declarations: [ ]
})

export class PagesModule { }