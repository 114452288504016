import { NgModule } from '@angular/core';

import { DatePipe } from './date';
import { CurrencyPipe } from './currency';
import { PhonePipe } from './phone';

@NgModule({
    declarations: [
        DatePipe,
        CurrencyPipe,
        PhonePipe,
    ],
    exports: [
        DatePipe,
        CurrencyPipe,
        PhonePipe,
    ]
})

export class PipesModule { }
