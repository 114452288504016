/* tslint:disable */
export * from './SolicitacaoServico';
export * from './Transacao';
export * from './Transportador';
export * from './Reserva';
export * from './Usuario';
export * from './Vendedor';
export * from './Hub';
export * from './Transporte';
export * from './Subcategoria';
export * from './Produto';
export * from './Venda';
export * from './Carteira';
export * from './ProdutoFotos';
export * from './Container';
export * from './Categoria';
export * from './Plano';
export * from './Fotografo';
export * from './FAQ';
export * from './DadosBancario';
export * from './PostagemSocial';
export * from './TipoPostagem';
export * from './Marca';
export * from './Secao';
export * from './SecoesEProdutos';
export * from './ProdutoTransporte';
export * from './Integracao';
export * from './Box';
export * from './HistoricoProduto';
export * from './BaseModels';

