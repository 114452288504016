import { NotificationBarComponent } from './notification-bar.component';
import { NotificationBarService } from './notification-bar.service';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
      NotificationBarComponent
    ],
    declarations: [
      NotificationBarComponent
    ]
})

export class NotificationBarModule {

    constructor() {

    }

  public static forRoot(): ModuleWithProviders<NotificationBarModule> {
    return {
          ngModule: NotificationBarModule,
          providers: [
            NotificationBarService
          ]
      };
  }

}
