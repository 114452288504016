/* tslint:disable */

declare var Object: any;
export interface ProdutoFotosInterface {
  "id"?: string;
  "fotoUrl": string;
  "destaque"?: boolean;
  "produtoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  produto?: any;
}

export class ProdutoFotos implements ProdutoFotosInterface {
  "id": string;
  "fotoUrl": string;
  "destaque": boolean;
  "produtoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  produto: any;
  constructor(data?: ProdutoFotosInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProdutoFotos`.
   */
  public static getModelName() {
    return "ProdutoFotos";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProdutoFotos for dynamic purposes.
  **/
  public static factory(data: ProdutoFotosInterface): ProdutoFotos{
    return new ProdutoFotos(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProdutoFotos',
      plural: 'ProdutoFotos',
      path: 'ProdutoFotos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "fotoUrl": {
          name: 'fotoUrl',
          type: 'string'
        },
        "destaque": {
          name: 'destaque',
          type: 'boolean'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produto: {
          name: 'produto',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
      }
    }
  }
}
