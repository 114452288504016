/* tslint:disable */
import {
  Produto
} from '../index';

declare var Object: any;
export interface ReservaInterface {
  "id"?: string;
  "dataReserva": Date;
  "periodo"?: string;
  "cliente"?: string;
  "status"?: string;
  "sinal"?: number;
  "valorReceber"?: number;
  "quantidadeReservada"?: number;
  "observacao"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  "produtoId"?: string;
  produto?: Produto;
}

export class Reserva implements ReservaInterface {
  "id": string;
  "dataReserva": Date;
  "periodo": string;
  "cliente": string;
  "status": string;
  "sinal": number;
  "valorReceber": number;
  "quantidadeReservada": number;
  "observacao": string;
  "createdAt": Date;
  "updatedAt": Date;
  "produtoId": string;
  produto: Produto;
  constructor(data?: ReservaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Reserva`.
   */
  public static getModelName() {
    return "Reserva";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Reserva for dynamic purposes.
  **/
  public static factory(data: ReservaInterface): Reserva{
    return new Reserva(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Reserva',
      plural: 'Reservas',
      path: 'Reservas',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "dataReserva": {
          name: 'dataReserva',
          type: 'Date'
        },
        "periodo": {
          name: 'periodo',
          type: 'string'
        },
        "cliente": {
          name: 'cliente',
          type: 'string',
          default: 'Sem dados'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "sinal": {
          name: 'sinal',
          type: 'number',
          default: 0
        },
        "valorReceber": {
          name: 'valorReceber',
          type: 'number',
          default: 0
        },
        "quantidadeReservada": {
          name: 'quantidadeReservada',
          type: 'number',
          default: 0
        },
        "observacao": {
          name: 'observacao',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
      },
      relations: {
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
      }
    }
  }
}
