<div class="container login-container">
  <div class="login-panel panel panel-default col-s2">
    <br />
    <img class="center" src="/assets/images/Tag2u-logo.png" />
    <br />

    <div class="panel-body">
      <form name="loginForm" (submit)="login($event, email.value, password.value)" novalidate #f="ngForm">
        <fieldset>
          <div class="form-group">
            <input form="loginForm" class="form-control" ngModel type="email" placeholder="Email" id="email" #email name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
          </div>
          <div class="form-group">
            <input form="loginForm" class="form-control" ngModel type="password" id="password" placeholder="Senha" #password
              name="password" required>
          </div>
          <div class="">
              <a class="" routerLink="/reset">Esqueceu sua senha?</a>
              <a class="right" routerLink="/cadastrar">Não tem conta? Crie agora!</a>
          </div>
          
          <br />
          <button type="submit" class="button big center" [disabled]="!f.form.valid">Login</button>
        </fieldset>
      </form>
    </div>
  </div>
</div>
