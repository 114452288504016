/* tslint:disable */
import {
  Transporte,
  DadosBancario
} from '../index';

declare var Object: any;
export interface TransportadorInterface {
  "id"?: string;
  "nome": string;
  "contato": string;
  "cnh"?: string;
  "rg"?: string;
  "placaVeiculo"?: string;
  "porteVeiculo"?: string;
  "tipoVeiculo"?: string;
  "rodizioVeiculo"?: string;
  "avaliacao"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  transportes?: Transporte[];
  dadosBancarios?: DadosBancario[];
}

export class Transportador implements TransportadorInterface {
  "id": string;
  "nome": string;
  "contato": string;
  "cnh": string;
  "rg": string;
  "placaVeiculo": string;
  "porteVeiculo": string;
  "tipoVeiculo": string;
  "rodizioVeiculo": string;
  "avaliacao": number;
  "createdAt": Date;
  "updatedAt": Date;
  transportes: Transporte[];
  dadosBancarios: DadosBancario[];
  constructor(data?: TransportadorInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transportador`.
   */
  public static getModelName() {
    return "Transportador";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transportador for dynamic purposes.
  **/
  public static factory(data: TransportadorInterface): Transportador{
    return new Transportador(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transportador',
      plural: 'Transportadores',
      path: 'Transportadores',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "contato": {
          name: 'contato',
          type: 'string'
        },
        "cnh": {
          name: 'cnh',
          type: 'string'
        },
        "rg": {
          name: 'rg',
          type: 'string'
        },
        "placaVeiculo": {
          name: 'placaVeiculo',
          type: 'string'
        },
        "porteVeiculo": {
          name: 'porteVeiculo',
          type: 'string'
        },
        "tipoVeiculo": {
          name: 'tipoVeiculo',
          type: 'string'
        },
        "rodizioVeiculo": {
          name: 'rodizioVeiculo',
          type: 'string'
        },
        "avaliacao": {
          name: 'avaliacao',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        transportes: {
          name: 'transportes',
          type: 'Transporte[]',
          model: 'Transporte',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'transportadorId'
        },
        dadosBancarios: {
          name: 'dadosBancarios',
          type: 'DadosBancario[]',
          model: 'DadosBancario',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'transportadorId'
        },
      }
    }
  }
}
