import { BaseService } from 'app/shared/services/base';
import { Component, OnInit, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Fotografo } from 'app/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends BaseService implements OnInit {

  public error = true;
  public returnUrl: string;
  public fotografo: Fotografo = new Fotografo();

  constructor(
    private _base: Injector,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router) {
    super(_base);
  }

  public ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this._auth.logout();
  }

  public login(event, email, password) {
    event.preventDefault();

    this.fotografo.email = email;
    this.fotografo.password = password;

    this.efetuarLogin();
  }

  public efetuarLogin() {
    this._loading.start();

    this._auth.login(this.fotografo)
      .subscribe( () => {
        if (this._auth.logged()) {
          this._loading.stop();
          this._toast.success('Login realizado com sucesso');
          this.router.navigateByUrl(this.returnUrl);
        }
      }, error => {
        this._loading.stop();
        this._toast.error(error);
      });
  }
}
