import { PipesModule } from './pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { CepModule } from './services/cep/cep.module';
import { HttpClientModule } from '@angular/common/http';
import { LoadingModule } from 'app/shared/services/loading';
import { AlertModule } from 'app/shared/services/alert';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { IconsModule } from './modules/icons.module';

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    LoadingModule,
    CepModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    PipesModule,
    IconsModule
  ],
  exports: [
    CommonModule,
    AlertModule,
    LoadingModule,
    CepModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    PipesModule,
    IconsModule
  ],
  declarations: []
})
export class SharedModule { }
