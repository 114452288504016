import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent {
  public menuStatus = false;
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  public logout(): void{
    this.authService.logout();
    this.router.navigate(['login']);
  }

  public toggleMenu(): void {
    this.menuStatus = !this.menuStatus;
  }
}
