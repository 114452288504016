/* tslint:disable */
import {
  PostagemSocial
} from '../index';

declare var Object: any;
export interface TipoPostagemInterface {
  "id"?: string;
  "nome"?: string;
  "ativo"?: boolean;
  "createdAt": Date;
  "updatedAt": Date;
  postagens?: PostagemSocial[];
}

export class TipoPostagem implements TipoPostagemInterface {
  "id": string;
  "nome": string;
  "ativo": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  postagens: PostagemSocial[];
  constructor(data?: TipoPostagemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TipoPostagem`.
   */
  public static getModelName() {
    return "TipoPostagem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TipoPostagem for dynamic purposes.
  **/
  public static factory(data: TipoPostagemInterface): TipoPostagem{
    return new TipoPostagem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TipoPostagem',
      plural: 'TipoPostagens',
      path: 'TipoPostagens',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "ativo": {
          name: 'ativo',
          type: 'boolean',
          default: true
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        postagens: {
          name: 'postagens',
          type: 'PostagemSocial[]',
          model: 'PostagemSocial',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'tipoPostagemId'
        },
      }
    }
  }
}
