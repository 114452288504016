import { Injectable, Inject, EventEmitter } from '@angular/core';
import { TRANSLATIONS } from './translations';

@Injectable()
export class TranslateService {

    private _defaultLang: string;
    private _currentLang: string;
    private _fallback: boolean;

    public onLangChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor( @Inject(TRANSLATIONS) private _translations: any) {

    }

    public get currentLang() {
        return this._currentLang || this._defaultLang;
    }

    public setDefaultLang() {
        this._defaultLang = this.loadLang();
        this.saveLang(this._defaultLang);
    }

    public enableFallback(enable: boolean) {
        this._fallback = enable;
    }

    public use(lang: string): void {
        this._currentLang = lang;
        this.saveLang(this._currentLang);
        this.onLangChanged.emit(lang);
    }

    public translate(key: string): string {

        const translation = key;

        // found in current language
        if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
            return this._translations[this.currentLang][key];
        }

        // fallback disabled
        if (!this._fallback) {
            return translation;
        }

        // found in default language
        if (this._translations[this._defaultLang] && this._translations[this._defaultLang][key]) {
            return this._translations[this._defaultLang][key];
        }

        // not found
        return translation;

    }

    public instant(key: string) {
        return this.translate(key);
    }

    private loadLang = () => {
        return localStorage.getItem('lang').match(/pt|en|es/) ? localStorage.getItem('lang') : 'en';
    }

    private saveLang = (lang: string) => {
        localStorage.setItem('lang', lang);
    }

}
