<div class="container login-container">
  <div class="login-panel panel panel-default col-s-8">
    <div class="panel-body">
      <form name="loginForm" (submit)="checkData($event)" novalidate #f="ngForm">
        <fieldset>
          <div class="form-group">
            <input form="loginForm" class="form-control" [(ngModel)]="usuario.nome" type="text" placeholder="Nome"
              id="nome" #nome name="nome" required>
          </div>
          <div class="form-group">
            <input form="loginForm" class="form-control" [(ngModel)]="usuario.email" type="email" placeholder="Email"
              id="email" #email name="email" required>
          </div>
          <div class="form-group">
            <input form="loginForm" class="form-control" [(ngModel)]="usuario.celular" type="text"
              placeholder="Whatsapp ou telefone" id="celular" #celular name="celular" [textMask]="{mask: celularMask}"
              required>
          </div>
          <div class="form-group">
            <input form="loginForm" class="form-control" [(ngModel)]="usuario.password" type="password" id="password"
              placeholder="Senha" #password name="password" required>
          </div>
          <div class="form-group">
            <input form="loginForm" class="form-control" [(ngModel)]="confirm" type="password" id="confirm"
              placeholder="Senha" #confirmPass name="confirm" required>
          </div>
          <div class="">
            <a class="right" routerLink="/login">Já tem conta? Clique aqui para entrar!</a>
          </div>

          <br />
          <img *ngIf="loading" class="loading" src="/assets/images/loader.svg" alt="carregando...">
          <button *ngIf="!loading" type="submit" class="button big center" [disabled]="!f.form.valid">Login</button>
        </fieldset>
      </form>
    </div>
  </div>
</div>