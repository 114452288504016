import { BaseService } from 'app/shared/services/base';
import { Component, OnInit, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SDKToken } from 'app/api//models/BaseModels';
import { LoopBackAuth } from 'app/api/';

import { AuthService } from 'app/auth/auth.service';

@Component({
    selector: 'app-setpassword',
    templateUrl: './setpassword.component.html'
})
export class SetpasswordComponent extends BaseService implements OnInit {

    public actual: string;
    public confirm: string;
    public password: string;
    public breadcrumb: any[];
    public passwordEqual = false;
    public accessToken: SDKToken;

    constructor(
        private _base: Injector,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private authLoop: LoopBackAuth) {
          super(_base);
    }

    public ngOnInit() {
        if (this.route.snapshot.queryParams.access_token) {
            this.breadcrumb = [{ name: 'Redefinir senha', url: '/reset' }];
            this.accessToken = this.route.snapshot.queryParams.access_token;
        } else {
            this.router.navigateByUrl('/login');
            this._loading.stop();
        }
    }

    public setpassword(event) {
        this._loading.start();
        event.preventDefault();
        if (this.confirm === this.password) {
            this.authService.setPassword(this.password)
                .then(res => {
                    this._loading.stop();
                    this._toast.success('Senha redefinida com sucesso.');
                    this.router.navigateByUrl('/login');
                })
                .catch(error => {
                    this._loading.stop();
                });
        } else {
            this._loading.stop();
            this._toast.error('Senhas não conferem');
        }
    }

    public validPassord() {
        this.passwordEqual = this.confirm !== this.password;
    }

    public reset() {
        this.actual = '';
        this.confirm = '';
        this.password = '';
    }

}
