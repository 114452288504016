/* tslint:disable */
import {
  Produto,
  TipoPostagem
} from '../index';

declare var Object: any;
export interface PostagemSocialInterface {
  "id"?: string;
  "link"?: string;
  "produtoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  "tipoPostagemId"?: string;
  produto?: Produto;
  tiposPostagem?: TipoPostagem;
}

export class PostagemSocial implements PostagemSocialInterface {
  "id": string;
  "link": string;
  "produtoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "tipoPostagemId": string;
  produto: Produto;
  tiposPostagem: TipoPostagem;
  constructor(data?: PostagemSocialInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PostagemSocial`.
   */
  public static getModelName() {
    return "PostagemSocial";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PostagemSocial for dynamic purposes.
  **/
  public static factory(data: PostagemSocialInterface): PostagemSocial{
    return new PostagemSocial(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PostagemSocial',
      plural: 'PostagemSociais',
      path: 'PostagemSociais',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "link": {
          name: 'link',
          type: 'string'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "tipoPostagemId": {
          name: 'tipoPostagemId',
          type: 'string'
        },
      },
      relations: {
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
        tiposPostagem: {
          name: 'tiposPostagem',
          type: 'TipoPostagem',
          model: 'TipoPostagem',
          relationType: 'belongsTo',
                  keyFrom: 'tipoPostagemId',
          keyTo: 'id'
        },
      }
    }
  }
}
