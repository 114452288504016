import { NgModule, ModuleWithProviders } from '@angular/core';
import { environment } from 'environments/environment';
import { SDKBrowserModule, LoopBackConfig } from 'app/api';

@NgModule({
    imports: [
        SDKBrowserModule.forRoot()
    ]
})

export class DataModule {

    constructor() {

        LoopBackConfig.setBaseURL(environment.API_URL);
        LoopBackConfig.setApiVersion(environment.API_VERSION);
        LoopBackConfig.setDebugMode(environment.API_DEBUG);

    }

    public static forRoot(): ModuleWithProviders<DataModule> {
        return {
            ngModule: DataModule,
            providers: []
        };
    }
}
