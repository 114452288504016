/* tslint:disable */
import {
  Usuario,
  Vendedor,
  Fotografo
} from '../index';

declare var Object: any;
export interface SolicitacaoServicoInterface {
  "id"?: string;
  "status": string;
  "data"?: Date;
  "produtos"?: any;
  "orderId": string;
  "pagamento"?: string;
  "responsavel"?: string;
  "createdAt": Date;
  "cep"?: string;
  "logradouro"?: string;
  "complemento"?: string;
  "numero"?: string;
  "bairro"?: string;
  "cidade"?: string;
  "estado"?: string;
  "obsStatus"?: string;
  "updatedAt": Date;
  "usuarioId"?: string;
  "vendedorId"?: string;
  "fotografoId"?: string;
  usuario?: Usuario;
  vendedor?: Vendedor;
  fotografo?: Fotografo;
}

export class SolicitacaoServico implements SolicitacaoServicoInterface {
  "id": string;
  "status": string;
  "data": Date;
  "produtos": any;
  "orderId": string;
  "pagamento": string;
  "responsavel": string;
  "createdAt": Date;
  "cep": string;
  "logradouro": string;
  "complemento": string;
  "numero": string;
  "bairro": string;
  "cidade": string;
  "estado": string;
  "obsStatus": string;
  "updatedAt": Date;
  "usuarioId": string;
  "vendedorId": string;
  "fotografoId": string;
  usuario: Usuario;
  vendedor: Vendedor;
  fotografo: Fotografo;
  constructor(data?: SolicitacaoServicoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SolicitacaoServico`.
   */
  public static getModelName() {
    return "SolicitacaoServico";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SolicitacaoServico for dynamic purposes.
  **/
  public static factory(data: SolicitacaoServicoInterface): SolicitacaoServico{
    return new SolicitacaoServico(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SolicitacaoServico',
      plural: 'SolicitacoesServico',
      path: 'SolicitacoesServico',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'Date'
        },
        "produtos": {
          name: 'produtos',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "pagamento": {
          name: 'pagamento',
          type: 'string'
        },
        "responsavel": {
          name: 'responsavel',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "cep": {
          name: 'cep',
          type: 'string'
        },
        "logradouro": {
          name: 'logradouro',
          type: 'string'
        },
        "complemento": {
          name: 'complemento',
          type: 'string'
        },
        "numero": {
          name: 'numero',
          type: 'string'
        },
        "bairro": {
          name: 'bairro',
          type: 'string'
        },
        "cidade": {
          name: 'cidade',
          type: 'string'
        },
        "estado": {
          name: 'estado',
          type: 'string'
        },
        "obsStatus": {
          name: 'obsStatus',
          type: 'string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "usuarioId": {
          name: 'usuarioId',
          type: 'string'
        },
        "vendedorId": {
          name: 'vendedorId',
          type: 'string'
        },
        "fotografoId": {
          name: 'fotografoId',
          type: 'string'
        },
      },
      relations: {
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'usuarioId',
          keyTo: 'id'
        },
        vendedor: {
          name: 'vendedor',
          type: 'Vendedor',
          model: 'Vendedor',
          relationType: 'belongsTo',
                  keyFrom: 'vendedorId',
          keyTo: 'id'
        },
        fotografo: {
          name: 'fotografo',
          type: 'Fotografo',
          model: 'Fotografo',
          relationType: 'belongsTo',
                  keyFrom: 'fotografoId',
          keyTo: 'id'
        },
      }
    }
  }
}
