import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth.routes';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

import { ChangepasswordComponent } from 'app/auth/';
import { LoginComponent } from 'app/auth/';
import { RegisterComponent } from 'app/auth/';
import { ResetComponent } from 'app/auth/';
import { SetpasswordComponent } from 'app/auth/';
import { LayoutModule } from '../layout/layout.module';
import { CookieBrowser } from 'app/api';

@NgModule({
    imports: [
        CommonModule,
        AuthRoutingModule,
        FormsModule,
        LayoutModule,
        SharedModule,
        
    ],
    declarations: [
        ChangepasswordComponent,
        LoginComponent,
        RegisterComponent,
        ResetComponent,
        SetpasswordComponent
    ],
    exports: [
        ChangepasswordComponent,
        LoginComponent,
        RegisterComponent,
        ResetComponent,
        SetpasswordComponent
    ],
    providers: [
        AuthService,
        AuthGuard,
        CookieBrowser
    ]
})

export class AuthModule {

}
