/* tslint:disable */
import {
  Produto,
  Hub
} from '../index';

declare var Object: any;
export interface BoxInterface {
  "boxId"?: string;
  "nome": string;
  "tamanho"?: number;
  "custo"?: number;
  "seguro"?: number;
  "pagamento"?: number;
  "contrato"?: number;
  "dataLocacao"?: string;
  "ativo"?: boolean;
  "hubId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos?: Produto[];
  hub?: Hub;
}

export class Box implements BoxInterface {
  "boxId": string;
  "nome": string;
  "tamanho": number;
  "custo": number;
  "seguro": number;
  "pagamento": number;
  "contrato": number;
  "dataLocacao": string;
  "ativo": boolean;
  "hubId": string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos: Produto[];
  hub: Hub;
  constructor(data?: BoxInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Box`.
   */
  public static getModelName() {
    return "Box";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Box for dynamic purposes.
  **/
  public static factory(data: BoxInterface): Box{
    return new Box(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Box',
      plural: 'Boxes',
      path: 'Boxes',
      idName: 'boxId',
      properties: {
        "boxId": {
          name: 'boxId',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "tamanho": {
          name: 'tamanho',
          type: 'number',
          default: 0
        },
        "custo": {
          name: 'custo',
          type: 'number',
          default: 0
        },
        "seguro": {
          name: 'seguro',
          type: 'number',
          default: 0
        },
        "pagamento": {
          name: 'pagamento',
          type: 'number',
          default: 0
        },
        "contrato": {
          name: 'contrato',
          type: 'number',
          default: 0
        },
        "dataLocacao": {
          name: 'dataLocacao',
          type: 'string'
        },
        "ativo": {
          name: 'ativo',
          type: 'boolean',
          default: false
        },
        "hubId": {
          name: 'hubId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produtos: {
          name: 'produtos',
          type: 'Produto[]',
          model: 'Produto',
          relationType: 'hasMany',
                  keyFrom: 'boxId',
          keyTo: 'boxId'
        },
        hub: {
          name: 'hub',
          type: 'Hub',
          model: 'Hub',
          relationType: 'belongsTo',
                  keyFrom: 'hubId',
          keyTo: 'id'
        },
      }
    }
  }
}
