import { NgModule, ModuleWithProviders } from '@angular/core';

import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';
import { TRANSLATION_PROVIDERS } from './translations';

@NgModule({
    exports: [
        TranslatePipe
    ],
    declarations: [
        TranslatePipe
    ]
})

export class TranslateModule {

    constructor() {

    }

    public static forRoot(): ModuleWithProviders<TranslateModule> {
        return {
            ngModule: TranslateModule,
            providers: [
                TranslateService,
                TRANSLATION_PROVIDERS
            ]
        };
    }

}
