import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastService {


    constructor(private toastrService: ToastrService) {
    }

    /**
     *
     *
     * @param {string} message
     * @param {string} type
     * @param {string} [title='']
     * @param {boolean} [important=false]
     * @memberof ToastService
     */
    public message(message: string, type: string, title: string = null, important = false): void {
        if (important) {
            this.toastrService.toastrConfig.closeButton = true;
            this.toastrService.toastrConfig.timeOut = 0;
        }
        switch (type) {
            case 'error':
                this.error(message, title);
                break;
            case 'warn':
                this.warning(message, title);
                break;
            case 'info':
                this.info(message, title);
                break;
            case 'success':
                this.success(message, title);
                break;
            default:
                this.success(message, title);
        }
    }

    /**
     *
     *
     * @param {string} message
     * @param {string} [title='']
     * @param {boolean} [important=false]
     * @memberof ToastService
     */
    public success(message: string, title?: string, important = false): void {
        if (important) {
            this.toastrService.toastrConfig.closeButton = true;
            this.toastrService.toastrConfig.timeOut = 0;
        }
        this.toastrService.success(message, title);
    }

    /**
     *
     *
     * @param {string} message
     * @param {string} [title='']
     * @param {boolean} [important=false]
     * @memberof ToastService
     */
    public warning(message: any, title?: string, important = false): void {
        if (important) {
            this.toastrService.toastrConfig.closeButton = true;
            this.toastrService.toastrConfig.timeOut = 0;
        }
        if (typeof (message) === 'object') {
            this.toastrService.warning(message.message, title);
        } else {
            this.toastrService.warning(message, title);
        }
    }

    /**
     *
     *
     * @param {string} message
     * @param {string} [title='']
     * @param {boolean} [important=false]
     * @memberof ToastService
     */
    public error(message: any, title?: string, important = false): void {
        if (important) {
            this.toastrService.toastrConfig.closeButton = true;
            this.toastrService.toastrConfig.timeOut = 0;
        }
        if (typeof(message) === 'object') {
            this.toastrService.error(message.message, title);
        } else {
            this.toastrService.error(message, title);
        }
    }

    /**
     *
     *
     * @param {string} message
     * @param {string} [title='']
     * @param {boolean} [important=false]
     * @memberof ToastService
     */
    public info(message: string, title?: string, important = false): void {
        this.toastrService.info(message, title);
    }

}
