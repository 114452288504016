export const environment = {
  production: false,

  API_URL: 'https://api-dev.tag2u.com.br',
  API_VERSION: 'api',
  API_DEBUG: true,
  LOJA_URL: 'https://loja-dev.tag2u.com.br',
  VENDEDOR_URL: 'https://vendedor-dev.tag2u.com.br',
  IMAGE_UPLOADER_URL: 'http://localhost:3008/upload',
  // IMAGE_UPLOADER_URL: 'https://uploader-dev.tag2u.com.br/upload',

  AWS: {
    accessKeyId: 'AKIAY4IC5FX2XDVTNUS3',
    secretAccessKey: '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',
    region: 'sa-east-1',
    bucketName: 'tag2u-dev',
    url: 'https://tag2u-dev.s3-sa-east-1.amazonaws.com/',
  },
};
