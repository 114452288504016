import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { HeaderComponent } from './header/header.component';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { FormsModule } from '@angular/forms';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, FormsModule],
  exports: [NavComponent, HeaderComponent, MobileNavComponent, MobileMenuComponent],
  declarations: [NavComponent, HeaderComponent, MobileNavComponent, MobileMenuComponent],
})
export class LayoutModule {}
