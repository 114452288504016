/* tslint:disable */
import {
  Produto,
  Secao
} from '../index';

declare var Object: any;
export interface SecoesEProdutosInterface {
  "id"?: string;
  "produtoId"?: string;
  "secaoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos?: Produto;
  secao?: Secao;
}

export class SecoesEProdutos implements SecoesEProdutosInterface {
  "id": string;
  "produtoId": string;
  "secaoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  produtos: Produto;
  secao: Secao;
  constructor(data?: SecoesEProdutosInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SecoesEProdutos`.
   */
  public static getModelName() {
    return "SecoesEProdutos";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SecoesEProdutos for dynamic purposes.
  **/
  public static factory(data: SecoesEProdutosInterface): SecoesEProdutos{
    return new SecoesEProdutos(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SecoesEProdutos',
      plural: 'SecoesEProdutos',
      path: 'SecoesEProdutos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "secaoId": {
          name: 'secaoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        produtos: {
          name: 'produtos',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
                  keyFrom: 'produtoId',
          keyTo: 'id'
        },
        secao: {
          name: 'secao',
          type: 'Secao',
          model: 'Secao',
          relationType: 'belongsTo',
                  keyFrom: 'secaoId',
          keyTo: 'id'
        },
      }
    }
  }
}
