import { NgModule, ModuleWithProviders } from '@angular/core';

import { CepService } from './cep.service';

@NgModule({})

export class CepModule {

    constructor() {

    }

    public static forRoot(): ModuleWithProviders<CepModule> {
        return {
            ngModule: CepModule,
            providers: [
                CepService
            ]
        };
    }

}
