<aside class="dashboard-nav">
  <nav class="dashboard-nav-inner">
    <a routerLink="/dashboard">
      <img class="logo-dahsboard" src="assets/images/Tag2u-logo.png" alt="logo" />
    </a>
    <ul data-submenu-title="Principal">
      <!-- <li routerLinkActive="active">
        <a routerLink="/dashboard">
          <i-feather class="icon" name="home"></i-feather> Visão geral
        </a>
      </li> -->
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/produtos/adicionar-lote">
          <i-feather class="icon" name="plus-circle"></i-feather> Adicionar produto
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/produtos"> <i-feather class="icon" name="archive"></i-feather> Todos os Produtos </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/perfil"> <i-feather class="icon" name="user"></i-feather> Meu Perfil </a>
      </li>
      <br />
      <li routerLinkActive="active">
        <a routerLink="/solicitacoes"> <i-feather class="icon" name="camera"></i-feather> Visita técnica </a>
      </li>
    </ul>

    <ul data-submenu-title="Conta">
      <li>
        <a [routerLink]="['login']" (click)="logout()"> <i-feather class="icon" name="log-out"></i-feather> Sair </a>
      </li>
    </ul>
  </nav>
</aside>
