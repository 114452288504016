/* tslint:disable */
import {
  Hub,
  Vendedor,
  Usuario,
  Reserva,
  SolicitacaoServico,
  Venda,
  ProdutoFotos,
  Categoria,
  Plano,
  Fotografo,
  PostagemSocial,
  Marca,
  Secao,
  Integracao,
  Box
} from '../index';

declare var Object: any;
export interface ProdutoInterface {
  "id"?: string;
  "codCliente"?: string;
  "codProduto"?: string;
  "estoque"?: number;
  "cor"?: string;
  "linkReferencia"?: string;
  "estado"?: string;
  "modelo"?: string;
  "marca"?: string;
  "classificacao"?: string;
  "titulo": string;
  "tituloComposto"?: string;
  "motivovenda"?: string;
  "tempouso"?: string;
  "descricao"?: string;
  "altura"?: string;
  "largura"?: string;
  "profundidade"?: string;
  "partes"?: string;
  "codigoOrigem"?: string;
  "valormercado"?: number;
  "valorinicial"?: number;
  "valoratual"?: number;
  "valorsite"?: number;
  "valorsugerido"?: number;
  "valorcompratag"?: number;
  "formaPagamento"?: string;
  "valorpago"?: number;
  "status"?: string;
  "obs_status"?: string;
  "observacoes"?: string;
  "observacoesInternas"?: string;
  "material"?: string;
  "datapublicacao"?: Date;
  "datareanuncio"?: Date;
  "statusRepasse"?: string;
  "percentual"?: number;
  "planoTemp"?: string;
  "url"?: string;
  "olx"?: boolean;
  "usuarioOlx"?: string;
  "quantidadeDisponivel"?: number;
  "compraTag"?: boolean;
  "hubId"?: string;
  "vendedorId"?: string;
  "usuarioId"?: string;
  "antigoProprietarioId"?: string;
  "visitaId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  "categoriaId"?: string;
  "planoId"?: string;
  "fotografoId"?: string;
  "marcaId"?: string;
  "boxId"?: string;
  hub?: Hub;
  vendedor?: Vendedor;
  usuario?: Usuario;
  antigoProprietario?: Usuario;
  reservas?: Reserva[];
  visita?: SolicitacaoServico;
  venda?: Venda[];
  fotos?: ProdutoFotos[];
  categoria?: Categoria;
  plano?: Plano;
  fotografo?: Fotografo;
  postagens?: PostagemSocial[];
  fabricante?: Marca;
  secoes?: Secao[];
  integracoes?: Integracao[];
  box?: Box;
}

export class Produto implements ProdutoInterface {
  "id": string;
  "codCliente": string;
  "codProduto": string;
  "estoque": number;
  "cor": string;
  "linkReferencia": string;
  "estado": string;
  "modelo": string;
  "marca": string;
  "classificacao": string;
  "titulo": string;
  "tituloComposto": string;
  "motivovenda": string;
  "tempouso": string;
  "descricao": string;
  "altura": string;
  "largura": string;
  "profundidade": string;
  "partes": string;
  "codigoOrigem": string;
  "valormercado": number;
  "valorinicial": number;
  "valoratual": number;
  "valorsite": number;
  "valorsugerido": number;
  "valorcompratag": number;
  "formaPagamento": string;
  "valorpago": number;
  "status": string;
  "obs_status": string;
  "observacoes": string;
  "observacoesInternas": string;
  "material": string;
  "datapublicacao": Date;
  "datareanuncio": Date;
  "statusRepasse": string;
  "percentual": number;
  "planoTemp": string;
  "url": string;
  "olx": boolean;
  "usuarioOlx": string;
  "quantidadeDisponivel": number;
  "compraTag": boolean;
  "hubId": string;
  "vendedorId": string;
  "usuarioId": string;
  "antigoProprietarioId": string;
  "visitaId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "categoriaId": string;
  "planoId": string;
  "fotografoId": string;
  "marcaId": string;
  "boxId": string;
  hub: Hub;
  vendedor: Vendedor;
  usuario: Usuario;
  antigoProprietario: Usuario;
  reservas: Reserva[];
  visita: SolicitacaoServico;
  venda: Venda[];
  fotos: ProdutoFotos[];
  categoria: Categoria;
  plano: Plano;
  fotografo: Fotografo;
  postagens: PostagemSocial[];
  fabricante: Marca;
  secoes: Secao[];
  integracoes: Integracao[];
  box: Box;
  constructor(data?: ProdutoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Produto`.
   */
  public static getModelName() {
    return "Produto";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Produto for dynamic purposes.
  **/
  public static factory(data: ProdutoInterface): Produto{
    return new Produto(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Produto',
      plural: 'Produtos',
      path: 'Produtos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "codCliente": {
          name: 'codCliente',
          type: 'string'
        },
        "codProduto": {
          name: 'codProduto',
          type: 'string'
        },
        "estoque": {
          name: 'estoque',
          type: 'number',
          default: 1
        },
        "cor": {
          name: 'cor',
          type: 'string'
        },
        "linkReferencia": {
          name: 'linkReferencia',
          type: 'string'
        },
        "estado": {
          name: 'estado',
          type: 'string'
        },
        "modelo": {
          name: 'modelo',
          type: 'string'
        },
        "marca": {
          name: 'marca',
          type: 'string'
        },
        "classificacao": {
          name: 'classificacao',
          type: 'string'
        },
        "titulo": {
          name: 'titulo',
          type: 'string'
        },
        "tituloComposto": {
          name: 'tituloComposto',
          type: 'string'
        },
        "motivovenda": {
          name: 'motivovenda',
          type: 'string'
        },
        "tempouso": {
          name: 'tempouso',
          type: 'string'
        },
        "descricao": {
          name: 'descricao',
          type: 'string'
        },
        "altura": {
          name: 'altura',
          type: 'string'
        },
        "largura": {
          name: 'largura',
          type: 'string'
        },
        "profundidade": {
          name: 'profundidade',
          type: 'string'
        },
        "partes": {
          name: 'partes',
          type: 'string'
        },
        "codigoOrigem": {
          name: 'codigoOrigem',
          type: 'string'
        },
        "valormercado": {
          name: 'valormercado',
          type: 'number'
        },
        "valorinicial": {
          name: 'valorinicial',
          type: 'number'
        },
        "valoratual": {
          name: 'valoratual',
          type: 'number'
        },
        "valorsite": {
          name: 'valorsite',
          type: 'number'
        },
        "valorsugerido": {
          name: 'valorsugerido',
          type: 'number'
        },
        "valorcompratag": {
          name: 'valorcompratag',
          type: 'number'
        },
        "formaPagamento": {
          name: 'formaPagamento',
          type: 'string'
        },
        "valorpago": {
          name: 'valorpago',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "obs_status": {
          name: 'obs_status',
          type: 'string'
        },
        "observacoes": {
          name: 'observacoes',
          type: 'string'
        },
        "observacoesInternas": {
          name: 'observacoesInternas',
          type: 'string'
        },
        "material": {
          name: 'material',
          type: 'string'
        },
        "datapublicacao": {
          name: 'datapublicacao',
          type: 'Date'
        },
        "datareanuncio": {
          name: 'datareanuncio',
          type: 'Date'
        },
        "statusRepasse": {
          name: 'statusRepasse',
          type: 'string'
        },
        "percentual": {
          name: 'percentual',
          type: 'number',
          default: 50
        },
        "planoTemp": {
          name: 'planoTemp',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "olx": {
          name: 'olx',
          type: 'boolean',
          default: false
        },
        "usuarioOlx": {
          name: 'usuarioOlx',
          type: 'string'
        },
        "quantidadeDisponivel": {
          name: 'quantidadeDisponivel',
          type: 'number'
        },
        "compraTag": {
          name: 'compraTag',
          type: 'boolean',
          default: false
        },
        "hubId": {
          name: 'hubId',
          type: 'string'
        },
        "vendedorId": {
          name: 'vendedorId',
          type: 'string'
        },
        "usuarioId": {
          name: 'usuarioId',
          type: 'string'
        },
        "antigoProprietarioId": {
          name: 'antigoProprietarioId',
          type: 'string'
        },
        "visitaId": {
          name: 'visitaId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "categoriaId": {
          name: 'categoriaId',
          type: 'string'
        },
        "planoId": {
          name: 'planoId',
          type: 'string'
        },
        "fotografoId": {
          name: 'fotografoId',
          type: 'string'
        },
        "marcaId": {
          name: 'marcaId',
          type: 'string'
        },
        "boxId": {
          name: 'boxId',
          type: 'string'
        },
      },
      relations: {
        hub: {
          name: 'hub',
          type: 'Hub',
          model: 'Hub',
          relationType: 'belongsTo',
                  keyFrom: 'hubId',
          keyTo: 'id'
        },
        vendedor: {
          name: 'vendedor',
          type: 'Vendedor',
          model: 'Vendedor',
          relationType: 'belongsTo',
                  keyFrom: 'vendedorId',
          keyTo: 'id'
        },
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'usuarioId',
          keyTo: 'id'
        },
        antigoProprietario: {
          name: 'antigoProprietario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'antigoProprietarioId',
          keyTo: 'id'
        },
        reservas: {
          name: 'reservas',
          type: 'Reserva[]',
          model: 'Reserva',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'produtoId'
        },
        visita: {
          name: 'visita',
          type: 'SolicitacaoServico',
          model: 'SolicitacaoServico',
          relationType: 'belongsTo',
                  keyFrom: 'visitaId',
          keyTo: 'id'
        },
        venda: {
          name: 'venda',
          type: 'Venda[]',
          model: 'Venda',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'produtoId'
        },
        fotos: {
          name: 'fotos',
          type: 'ProdutoFotos[]',
          model: 'ProdutoFotos',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'produtoId'
        },
        categoria: {
          name: 'categoria',
          type: 'Categoria',
          model: 'Categoria',
          relationType: 'belongsTo',
                  keyFrom: 'categoriaId',
          keyTo: 'id'
        },
        plano: {
          name: 'plano',
          type: 'Plano',
          model: 'Plano',
          relationType: 'belongsTo',
                  keyFrom: 'planoId',
          keyTo: 'id'
        },
        fotografo: {
          name: 'fotografo',
          type: 'Fotografo',
          model: 'Fotografo',
          relationType: 'belongsTo',
                  keyFrom: 'fotografoId',
          keyTo: 'id'
        },
        postagens: {
          name: 'postagens',
          type: 'PostagemSocial[]',
          model: 'PostagemSocial',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'produtoId'
        },
        fabricante: {
          name: 'fabricante',
          type: 'Marca',
          model: 'Marca',
          relationType: 'belongsTo',
                  keyFrom: 'marcaId',
          keyTo: 'id'
        },
        secoes: {
          name: 'secoes',
          type: 'Secao[]',
          model: 'Secao',
          relationType: 'hasMany',
          modelThrough: 'SecoesEProdutos',
          keyThrough: 'secaoId',
          keyFrom: 'id',
          keyTo: 'produtoId'
        },
        integracoes: {
          name: 'integracoes',
          type: 'Integracao[]',
          model: 'Integracao',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'produtoId'
        },
        box: {
          name: 'box',
          type: 'Box',
          model: 'Box',
          relationType: 'belongsTo',
                  keyFrom: 'boxId',
          keyTo: 'boxId'
        },
      }
    }
  }
}
