import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Cep } from './cep.interface';

@Injectable({
  providedIn: 'root',
})
export class CepService {

    constructor(
        private http: HttpClient) {

    }

    /**
     *
     *
     * @param {string} cep
     * @returns {Promise<any>}
     * @memberof CepService
     */
    public async searchCep(cep: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<Cep>(`https://viacep.com.br/ws/${cep}/json/`).toPromise()
                .then((response: any) => {
                  if (response.erro) {
                    const error = new Error();
                    error.message = 'CEP não encontrado';
                    reject(error);
                  } else {
                    const data = {
                      bairro : response.bairro,
                      cep : response.cep,
                      cidade : response.localidade,
                      complemento : response.complemento,
                      estado : response.uf,
                      logradouro : response.logradouro
                    };
                    resolve(data);
                  }
                })
                .catch((err: any) => {
                    const error = new Error();
                    error.message = 'CEP não encontrado';
                    reject(error);
                });
        });
    }

}
