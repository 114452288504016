/* tslint:disable */
import {
  SolicitacaoServico
} from '../index';

declare var Object: any;
export interface FotografoInterface {
  "id"?: string;
  "nome": string;
  "informacoes"?: string;
  "contato"?: string;
  "email"?: string;
  "telefone"?: string;
  "celular"?: string;
  "site"?: string;
  "linkedin"?: string;
  "facebook"?: string;
  "twitter"?: string;
  "instagram"?: string;
  "youtube"?: string;
  "logourl"?: string;
  "ativo"?: boolean;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "enderecoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  "password"?: string;
  accessTokens?: any[];
  endereco?: any;
  solicitacoes?: SolicitacaoServico[];
}

export class Fotografo implements FotografoInterface {
  "id": string;
  "nome": string;
  "informacoes": string;
  "contato": string;
  "email": string;
  "telefone": string;
  "celular": string;
  "site": string;
  "linkedin": string;
  "facebook": string;
  "twitter": string;
  "instagram": string;
  "youtube": string;
  "logourl": string;
  "ativo": boolean;
  "realm": string;
  "username": string;
  "emailVerified": boolean;
  "enderecoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "password": string;
  accessTokens: any[];
  endereco: any;
  solicitacoes: SolicitacaoServico[];
  constructor(data?: FotografoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Fotografo`.
   */
  public static getModelName() {
    return "Fotografo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Fotografo for dynamic purposes.
  **/
  public static factory(data: FotografoInterface): Fotografo{
    return new Fotografo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Fotografo',
      plural: 'Fotografos',
      path: 'Fotografos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "nome": {
          name: 'nome',
          type: 'string'
        },
        "informacoes": {
          name: 'informacoes',
          type: 'string'
        },
        "contato": {
          name: 'contato',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "telefone": {
          name: 'telefone',
          type: 'string'
        },
        "celular": {
          name: 'celular',
          type: 'string'
        },
        "site": {
          name: 'site',
          type: 'string'
        },
        "linkedin": {
          name: 'linkedin',
          type: 'string'
        },
        "facebook": {
          name: 'facebook',
          type: 'string'
        },
        "twitter": {
          name: 'twitter',
          type: 'string'
        },
        "instagram": {
          name: 'instagram',
          type: 'string'
        },
        "youtube": {
          name: 'youtube',
          type: 'string'
        },
        "logourl": {
          name: 'logourl',
          type: 'string'
        },
        "ativo": {
          name: 'ativo',
          type: 'boolean'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "enderecoId": {
          name: 'enderecoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        endereco: {
          name: 'endereco',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'enderecoId',
          keyTo: 'id'
        },
        solicitacoes: {
          name: 'solicitacoes',
          type: 'SolicitacaoServico[]',
          model: 'SolicitacaoServico',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'fotografoId'
        },
      }
    }
  }
}
