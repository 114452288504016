import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SDKToken } from 'app/api//models/BaseModels';

import { LoopBackAuth } from 'app/api/';
import { ToastService, LoadingService } from 'app/shared/services/';
import { AuthService } from 'app/auth/auth.service';

@Component({
    selector: 'app-changepassword',
    templateUrl: './changepassword.component.html'
})
export class ChangepasswordComponent implements OnInit {

    public actual: string;
    public confirm: string;
    public password: string;
    public breadcrumb: any[];
    public passwordEqual = false;

    public accessToken: SDKToken;

    constructor(
        private authService: AuthService,
        private router: Router,
        private toast: ToastService,
        public loadingService: LoadingService,
        private route: ActivatedRoute,
        private authLoop: LoopBackAuth) {

    }

    public ngOnInit() {
    }

    public changepassword(event) {
        event.preventDefault();
        if (this.confirm === this.password) {
            this.loadingService.start();
            this.authService
                .changePassword(this.actual, this.password)
                .then(res => {
                    this.loadingService.stop();
                    this.toast.success('Password changed successfully');
                    this.router.navigate(['']);
                }).catch(error => {
                    this.loadingService.stop();
                    this.toast.error(error.message);
                });
        } else {
            this.toast.error('Passwords do not match');
        }
    }

    public validPassord() {
        this.passwordEqual = this.confirm !== this.password;
    }

    public reset() {
        this.actual = '';
        this.confirm = '';
        this.password = '';
    }

}
