import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingService } from './loading.service';

import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        LoadingComponent
    ],
    declarations: [
        LoadingComponent
    ]
})

export class LoadingModule {

    constructor() {

    }

    public static forRoot(): ModuleWithProviders<LoadingModule> {
        return {
            ngModule: LoadingModule,
            providers: [
                LoadingService
            ]
        };
    }

}
